<template>
  <b-row class="match-height">
    <b-col
      v-for="item in dataList"
      :key="item.id"
    >
      <package-card :data-item="item" />
    </b-col>
    <b-col cols="12">
      <div class="text-muted font-small-2 text-center">
        * Rapor adet birim fiyatına göre classic paket ile karşılaştırılmıştır.
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import PackageCard from '@/views/Dealers/Packages/components/PackageCard.vue'

export default {
  name: 'Packages',
  components: {
    BRow,
    BCol,
    PackageCard,
  },
  computed: {
    dataList() {
      return this.$store.getters['dealerPackages/dataList']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('dealerPackages/getDataList')
    },
  },
}
</script>
